<template name="component-name">
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <span class="h3 semiBold">Perfil</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-card class="light br-12 pa-6" outlined elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3">
                <span class="primary--text h4 semiBold">Perfil</span>
                <div class="avatar-container mt-8">
                  <div class="avatar d-flex align-center justify-center">
                    <v-avatar size="138px" v-ripple v-if="!avatar">
                      <img :src="profile_data.foto_perfil" alt="avatar" />
                    </v-avatar>
                    <v-avatar size="138px" v-ripple v-else>
                      <img :src="avatar.imageURL" alt="avatar" />
                    </v-avatar>
                  </div>
                  <div class="activator">
                    <image-input v-model="avatar">
                      <div slot="activator">
                        <v-btn icon>
                          <v-icon name="photo_camera">mdi-camera</v-icon>
                        </v-btn>
                      </div>
                    </image-input>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="9" class="mt-8">
                <v-row>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Rut" />
                    <!-- <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="profile_data.usuario"
                      placeholder="RUT"
                      :hide="false"
                      readonly="true"
                      :rules="[rules.rut, rules.required, rules.isValid]"
                      max_length="12"
                      :rut="true"
                      name="ProfileRut"
                  /> -->
                    <TextFieldRut
                      :text="profile_data.usuario"
                      color="#0077C8"
                      :readonly="readonlyRut"
                      :rules="[rules.required, rules.rut, rules.isValid]"
                      name="ProfileRut"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Nombre" />
                    <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="profile_data.nombre"
                      placeholder="Nombre"
                      :hide="false"
                      @input="profile_data.nombre = $event"
                      :rules="[rules.required]"
                      readonly="false"
                      name="ProfileName"
                  /></v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Primer apellido" />
                    <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="profile_data.primer_apellido"
                      placeholder="Primer apellido"
                      :hide="false"
                      @input="profile_data.primer_apellido = $event"
                      :rules="[rules.required]"
                      type="text"
                      readonly="false"
                      name="ProfileLastname"
                  /></v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Segundo apellido" />
                    <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="profile_data.segundo_apellido"
                      placeholder="Segundo apellido"
                      :hide="false"
                      @input="profile_data.segundo_apellido = $event"
                      readonly="false"
                      name="ProfileSecondLastName"
                  /></v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Teléfono" />
                    <TextFieldPhone
                      :text="profile_data.celular"
                      color="#0077C8"
                      :readonly="false"
                      :rules="[rules.required, rules.telefono]"
                      @input="profile_data.celular = $event"
                      name="ProfilePhone"
                    />
                    <!-- <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="profile_data.celular"
                      placeholder="Teléfono"
                      :hide="false"
                      @input="profile_data.celular = $event"
                      :rules="[rules.required, rules.telefono]"
                      readonly="false"
                      name="ProfilePhone"
                  /> -->
                  </v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Correo" />
                    <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="profile_data.correo_electronico"
                      placeholder="Correo"
                      :hide="false"
                      readonly="true"
                      @input="profile_data.correo_electronico = $event"
                      :rules="[rules.required, rules.email]"
                      name="ProfileEmail"
                  /></v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-10">
              <v-col cols="12" md="3">
                <span class="primary--text h4 semiBold">Contraseña</span>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Contraseña actual" />
                    <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="profile_data.clave"
                      placeholder="Contraseña actual"
                      readonly="false"
                      :hide="false"
                      :type="type"
                      @input="profile_data.clave = $event"
                      :rules="[rules.required]"
                      name="ProfileCurrentPass"
                  /></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Contraseña nueva" />
                    <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="NewPassword.text"
                      readonly="false"
                      placeholder="Contraseña nueva"
                      :hide="false"
                      :type="type"
                      @input="NewPassword.text = $event"
                      max_length="14"
                      :rules="[
                        rules.required,
                        rules.length,
                        rules.mayus,
                        rules.minus,
                        rules.number,
                      ]"
                      name="ProfileNewPass"
                  /></v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Confirmar contraseña" />
                    <Input
                      size="long"
                      color="#0077c8"
                      detail_color="white"
                      :model="ConfirmPassword.text"
                      placeholder="Confirmar contraseña"
                      :hide="false"
                      :type="type"
                      @input="ConfirmPassword.text = $event"
                      max_length="14"
                      :rules="ConfirmPassword.rules"
                      readonly="false"
                      name="ProfileConfirmPass"
                  /></v-col>
                </v-row>
                <v-row>
                  <div class="d-flex align-stretch w-100 justify-space-between">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-center">
                        <v-icon
                          :color="[
                            typeof nuevaPassLength === 'boolean'
                              ? 'success'
                              : 'error',
                          ]"
                          >{{
                            typeof nuevaPassLength === "boolean"
                              ? "mdi-check-circle"
                              : "mdi-close"
                          }}</v-icon
                        >
                        <span
                          class="ml-2"
                          :class="[
                            typeof nuevaPassLength === 'boolean'
                              ? 'success-text'
                              : 'error-text',
                          ]"
                        >
                          Al menos 8 caracteres</span
                        >
                      </div>
                      <div class="d-flex align-center mt-2">
                        <v-icon
                          :color="[
                            typeof nuevaPassMayus === 'boolean'
                              ? 'success'
                              : 'error',
                          ]"
                          >{{
                            typeof nuevaPassMayus === "boolean"
                              ? "mdi-check-circle"
                              : "mdi-close"
                          }}</v-icon
                        >
                        <span
                          class="ml-2"
                          :class="[
                            typeof nuevaPassMayus === 'boolean'
                              ? 'success-text'
                              : 'error-text',
                          ]"
                          >Una mayúscula</span
                        >
                      </div>
                      <div class="d-flex align-center mt-2">
                        <v-icon
                          :color="[
                            typeof nuevaPassMinus === 'boolean'
                              ? 'success'
                              : 'error',
                          ]"
                          >{{
                            typeof nuevaPassMinus === "boolean"
                              ? "mdi-check-circle"
                              : "mdi-close"
                          }}</v-icon
                        >
                        <span
                          class="ml-2"
                          :class="[
                            typeof nuevaPassMinus === 'boolean'
                              ? 'success-text'
                              : 'error-text',
                          ]"
                          >Una minúscula</span
                        >
                      </div>
                      <div class="d-flex align-center mt-2">
                        <v-icon
                          :color="[
                            typeof nuevaPassNumber === 'boolean'
                              ? 'success'
                              : 'error',
                          ]"
                          >{{
                            typeof nuevaPassNumber === "boolean"
                              ? "mdi-check-circle"
                              : "mdi-close"
                          }}</v-icon
                        >
                        <span
                          class="ml-2"
                          :class="[
                            typeof nuevaPassNumber === 'boolean'
                              ? 'success-text'
                              : 'error-text',
                          ]"
                          >Un número</span
                        >
                      </div>
                    </div>
                    <div class="align-end d-flex">
                      <v-btn
                        color="primary"
                        :disabled="!formValid"
                        @click="SaveProfile"
                        rounded
                        class="text-none"
                        width="120"
                        :loading="this.$store.getters.updateStatus"
                        >Guardar</v-btn
                      >
                    </div>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex justify-end"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ImageInput from "./ImageInput.vue";
import Input from "@/components/Inputs/Input.vue";
import LabelComponent from "@/components/Inputs/Label.vue";
import TextFieldPhone from "@/components/Inputs/TextFieldPhone";
import TextFieldRut from "@/components/Inputs/TextFieldRut";

import {
  validarContrasena,
  validarEmail,
  validarRutFormat,
  validarRut,
} from "@/store/resources/validate.js";
export default {
  components: {
    Input,
    ImageInput,
    LabelComponent,
    TextFieldPhone,
    TextFieldRut,
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
        this.toBase64(this.avatar.imageFile);
      },
      deep: true,
    },
    newpassword() {
      if (this.rules.length(this.newpassword)) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      avatar: null,
      saving: false,
      saved: false,
      type: "password",
      newpassword: "",
      confirmpassword: "",
      readonlyRut: true,
      profile_data: {
        nombre: "",
        primer_apellido: "",
        celular: "",
        segundo_apellido: "",
        correo_electronico: "",
        usuario: "",
        profile_data: "",
        foto_perfil: "",
      },
      profile_image: null,
      NewPassword: {
        text: "",
        rules: [
          (value) => !!value || "Contraseña requerida",

          (value) =>
            validarContrasena(value) ||
            "La contraseña debe ser alfanumerica y solo puede contener entre 8 a 15 caracteres.",
        ],
      },
      ConfirmPassword: {
        text: "",
        rules: [
          (value) => !!value || "Contraseña requerida",

          (value) => {
            return (
              value === this.NewPassword.text || "Las contraseñas no coinciden"
            );
          },
        ],
      },
      rules: {
        required: (value) => !!value || "Campo requerido.",
        length: (value) =>
          value.length >= 8 || "Deben ser igual o mayor a 8 digitos",
        mayus: (value) => {
          const pattern = /(?=.*[A-Z])/;
          return pattern.test(value) || "Debe contener al menos una mayúscula";
        },
        minus: (value) => {
          const pattern = /(?=.*[a-z])/;
          return pattern.test(value) || "Debe contener al menos una minúscula";
        },
        number: (value) => {
          const pattern = /(?=.*[0-9])/;
          return pattern.test(value) || "Debe contener al menos un número";
        },
        rut: (value) => validarRutFormat(value) || "Formato de RUT invalido",
        email: (value) => validarEmail(value) || "Correo invalido.",
        telefono: (value) => value.length == 9 || "Teléfono inválido",
        isValid: (value) =>
          validarRut(value) || "No se ha logrado reconocer el RUT",
      },
    };
  },
  methods: {
    toBase64(file) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          var fileData = reader.result.toString().split(",");

          this.profile_image = fileData[1];

          this.$emit("this.file", reader.result);
          resolve(this.profile_image);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    uploadImage() {
      this.saving = true;
      setTimeout(() => this.savedAvatar(), 1000);
    },
    savedAvatar() {
      this.saving = false;
      this.saved = true;
    },
    goBack() {
      this.$router.go(-1);
    },

    Show() {
      if (this.type === "password") this.type = "text";
      else this.type = "password";
    },

    SaveProfile() {
      var dataProfile = {
        nombre: this.profile_data.nombre,
        primer_apellido: this.profile_data.primer_apellido,
        segundo_apellido: this.profile_data.segundo_apellido,
        celular: this.profile_data.celular,
        correo_electronico: this.profile_data.correo_electronico,
        usuario: this.profile_data.usuario,
        clave_actual: this.profile_data.clave,
        clave_nueva: this.NewPassword.text,
        foto_perfil: this.profile_image,
        extension: "png",
      };

      this.$store
        .dispatch("UpdateProfile", dataProfile)
        .then((message) => {
          this.profile_data.clave = this.NewPassword.text;
          this.NewPassword.text = "";
          this.ConfirmPassword.text = "";
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "success",
          });
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        });
    },
  },
  created() {
    if (this.user) {
      this.profile_data.nombre = this.user.nombre;
      this.profile_data.primer_apellido = this.user.primer_apellido;
      this.profile_data.celular = this.user.celular;
      this.profile_data.segundo_apellido = this.user.segundo_apellido;
      this.profile_data.correo_electronico = this.user.correo_electronico;
      this.profile_data.usuario = this.user.usuario;
      this.profile_data.foto_perfil = this.user.foto_usuario;
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    nuevaPassLength() {
      if (this.rules.length(this.NewPassword.text)) {
        return this.rules.length(this.NewPassword.text);
      }

      return false;
    },
    nuevaPassNumber() {
      if (this.rules.number(this.NewPassword.text)) {
        return this.rules.number(this.NewPassword.text);
      }

      return false;
    },
    nuevaPassMayus() {
      if (this.rules.mayus(this.NewPassword.text)) {
        return this.rules.mayus(this.NewPassword.text);
      }

      return false;
    },
    nuevaPassMinus() {
      if (this.rules.minus(this.NewPassword.text)) {
        return this.rules.minus(this.NewPassword.text);
      }

      return false;
    },
    formValid() {
      return (
        this.profile_data.nombre &&
        this.profile_data.primer_apellido &&
        this.profile_data.celular &&
        this.profile_data.correo_electronico
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.avatar-container {
  /* background-color: red; */
  position: relative;
  height: 140px;
  width: 140px;
}
.avatar {
  height: 140px;
  width: 140px;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.activator {
  position: absolute;
  bottom: 0;
  right: 0px;
}
</style>
